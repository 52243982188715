<template>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <!-- modal for add new price record -->
                <b-overlay :show="add_price_show_overlay" rounded="sm">
                    <b-modal
                        id="modal-create"
                        cancel-variant="secondary"
                        ok-title="Add"
                        cancel-title="Cancel"
                        centered
                        title="Add new record"
                        @ok="addRecord"
                    >
                        <b-form>

                            <b-form-group label="UserId" label-for="userId">
                                <b-form-input id="userId" placeholder="user id" v-model="newRecord.userId" />
                            </b-form-group>

                            <b-row class="mb-3">
                                <b-col class="pl-0 mt-2 ml-3">
                                    <b-form-group label="country code">
                                        <v-select
                                            id="countryCode"
                                            label="text"
                                            :reduce="(text) => text.value"
                                            placeholder="location"
                                            v-model="newRecord.countryCode"
                                            :options="CountryOption"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-form-group label="Bandwidth_Mbps_min" label-for="Bandwidth_Mbps_min">
                                <b-form-input id="Bandwidth_Mbps_min" placeholder="Bandwidth_Mbps_min" v-model="newRecord.bandwidthMbpsMin" />
                            </b-form-group>

                            <b-form-group label="Bandwidth_Mbps_max" label-for="Bandwidth_Mbps_max">
                                <b-form-input id="Bandwidth_Mbps_max" placeholder="Bandwidth_Mbps_max" v-model="newRecord.bandwidthMbpsMax" />
                            </b-form-group>

                            <v-date-picker v-model="newRecord.expireTime" timezone="UTC" is24hr>
                                <template v-slot="{ inputValue, togglePopover }">
                                    <b-form-group label="expiration date">
                                        <b-input-group>
                                            <b-input-group-prepend is-text>
                                                <feather-icon icon="CalendarIcon" />
                                            </b-input-group-prepend>
                                            <b-form-input :value="inputValue" v-on:click="togglePopover" @keypress.prevent />
                                        </b-input-group>
                                    </b-form-group>
                                </template>
                            </v-date-picker>

                            <b-form-group label="Storage GB" label-for="storageGB">
                                <b-form-input id="storageGB" placeholder="storage GB" v-model="newRecord.storageTotalGB" />
                            </b-form-group>

                            <b-form-group label="Cpu" label-for="cpu">
                                <b-form-input id="cpu" placeholder="cpu" v-model="newRecord.cpu" />
                            </b-form-group>

                            <b-form-group label="Op sys" label-for="opSys">
                                <b-form-input id="opSys" placeholder="opSys" v-model="newRecord.op_sys" />
                            </b-form-group>

                            <b-form-group label="Count" label-for="count">
                                <b-form-input id="count" placeholder="count" v-model="newRecord.count" />
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-overlay>
            </div>
        </div>

        <!-- table -->
        <div class="col-12">
            <b-card header="Node">
                <div class="pr-3 pb-3 pl-3">
                    <b-row class="mb-3">
                        <b-col>
                            <b-button class="mr-3" variant="primary" v-b-modal.modal-create @click="clickAddRecord"> Add Record </b-button>

                            <VDropdown>
                                <b-button variant="secondary" class="mr-3 ml-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row class="mb-1">
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="Id">
                                                <b-form-input placeholder="record id" v-model="queryCondition.id" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-1">
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="User id">
                                                <b-form-input placeholder="user id" v-model="queryCondition.userId" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1">
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="Ip">
                                                <b-form-input placeholder="ip" v-model="queryCondition.ip" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <v-date-picker v-model="queryCondition.expireTime" timezone="UTC" is24hr>
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <b-form-group label="Before expiration date">
                                                <b-input-group>
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon icon="CalendarIcon" />
                                                    </b-input-group-prepend>
                                                    <b-form-input :value="inputValue" v-on:click="togglePopover" @keypress.prevent />
                                                </b-input-group>
                                            </b-form-group>
                                        </template>
                                    </v-date-picker>

                                    <b-row class="mb-1">
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="Country code">
                                                <v-select
                                                    id="countryCode"
                                                    label="text"
                                                    :reduce="(text) => text.value"
                                                    placeholder="location"
                                                    v-model="queryCondition.countryCode"
                                                    :options="CountryOption"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1">
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="Continent code">
                                                <v-select
                                                    id="continentCode"
                                                    label="text"
                                                    :reduce="(text) => text.value"
                                                    placeholder="continent"
                                                    v-model="queryCondition.continentCode"
                                                    :options="ContinentOption"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1">
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="Status">
                                                <v-select
                                                    id="status"
                                                    label="text"
                                                    :reduce="(text) => text.value"
                                                    placeholder="status"
                                                    v-model="queryCondition.status"
                                                    :options="nodeStatusOptions"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->

                                <span v-if="props.column.field === 'expire_unixtime'">
                                    <b-badge class="ml-1 mr-1" :variant="dateClass(props.row.expire_unixtime)">
                                        {{ parseTime(props.row.expire_unixtime) }}</b-badge
                                    >
                                </span>

                                <span v-else-if="props.column.field === 'action1'">
                                    <b-button variant="primary" @click="open_row_view_edit(props.row.originalIndex)">Edit/Delete</b-button>
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal id="modal_row_view_update" @hide="handleHide" title="view/edit" cancel-variant="secondary" cancel-title="Close" centered>
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group label="id" label-for="id">
                                    <b-form-input id="id" placeholder="id" v-model="this.focus.id" disabled />
                                </b-form-group>

                                <b-form-group label="UserId" label-for="userId">
                                    <b-form-input id="userId" placeholder="user id" v-model="newRecord.userId" disabled />
                                </b-form-group>

                                <b-form-group label="Ip" label-for="ip">
                                    <b-form-input id="ip" placeholder="ip" v-model="newRecord.ip" disabled />
                                </b-form-group>

                                <v-date-picker v-model="newRecord.expireTime" timezone="UTC" is24hr>
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <b-form-group label="expiration date">
                                            <b-input-group>
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="CalendarIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input :value="inputValue" v-on:click="togglePopover" @keypress.prevent />
                                            </b-input-group>
                                        </b-form-group>
                                    </template>
                                </v-date-picker>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left">
                                    <b-button variant="danger" @click="deleteRecord">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right">
                                    <b-button variant="primary" class="mr-3" @click="updateRecord">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import moment from "moment";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";

import {
    ALL_Continent,
    ALL_Country,
    CountryMap,
    ContinentMap,
    GetFullCountry,
    GetFullContinent,
    ContinentOption,
    CountryOption,
} from "../../location/location";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        VueGoodTable,
        vSelect,
        VDatePicker,
    },

    methods: {
        GetFullCountry,
        GetFullContinent,

        dateClass(unixTimeStamp) {
            let nowTimeStamp = moment().unix();
            let gap = unixTimeStamp - nowTimeStamp;
            if (gap < 0) {
                return "danger";
            } else if (gap < 3600 * 24 * 15) {
                return "warning";
            } else {
                return "success";
            }
        },

        parseTime(timeStamp) {
            return moment.unix(timeStamp).utc().format("YYYY-MM-DD");
        },

        clickAddRecord() {},
        clearQueryCondition() {
            this.queryCondition = {};
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.newRecord.userId = this.focus.user_id;
            this.newRecord.ip = this.focus.ip;
            this.newRecord.expireTime = new Date(this.focus.expire_unixtime * 1000);
            this.$bvModal.show("modal_row_view_update");
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async refreshTable() {
            this.table_show_overlay = true;
            let id = this.queryCondition.id ? parseInt(this.queryCondition.id) : null;
            let userId = this.queryCondition.userId ? parseInt(this.queryCondition.userId) : null;
            let ip = this.queryCondition.ip ? this.queryCondition.ip : null;
            let beforeExpireTime = this.queryCondition.expireTime ? this.queryCondition.expireTime : null;

            let eTime = null;
            if (beforeExpireTime !== null) {
                eTime = moment(beforeExpireTime).utc().endOf("day").unix();
            }

            let status = this.queryCondition.status ? this.queryCondition.status : null;

            let country_code = this.queryCondition.countryCode ? this.queryCondition.countryCode : null;
            let continent_code = this.queryCondition.continentCode ? this.queryCondition.continentCode : null;

            //queryAgentNode(id,userId,ip,beforeExpireTime,country_code,continent_code,status, limit, offset, token)
            let resp = await this.$api.agentNode.queryAgentNode(
                id,
                userId,
                ip,
                eTime,
                country_code,
                continent_code,
                status,
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.records;
            this.totalRows = resp.result.count;
        },

        async updateRecord() {
            let expireTime = this.newRecord.expireTime;
            if (expireTime === null || expireTime === "") {
                this.$bvToast.toast("expire time error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }
            let eTime = moment(expireTime).utc().endOf("day").unix();

            //updateAgentNode(idArray,bandwidth_Mbps_min,bandwidth_Mbps_max,expireTime,stor_total_GB,cpu,op_sys,token)
            let resp = await this.$api.agentNode.updateAgentNode(
                [this.focus.id],
                bandwidth_Mbps_min,
                bandwidth_Mbps_max,
                eTime,
                stor_total_GB,
                cpu,
                op_sys,
                this.$store.state.auth.my_web_token
            );
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("price updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");

            this.newRecord = {
                userId: 0,
                Ip: "",
                expireTime: "",
            };
        },

        async batchDelete() {},

        async deleteRecord() {
            let value = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete record [${this.focus.id}] ?`, {
                title: "Delete Record",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.agentNode.deleteAgentNode([this.focus.id], this.$store.state.auth.my_web_token);
                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("record deleted", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
                this.$bvModal.hide("modal_row_view_update");
            }
        },

        async addRecord(bvModalEvt) {

            // userId: 0,
            //     countryCode: "",
            //     bandwidthMbpsMin: 700,
            //     bandwidthMbpsMax: 800,
            //     storageTotalGB: 80,
            //     cpu: "",
            //     op_sys: "",
            //     expireDate: "",
            //     count: 0, 


            let userId = parseInt(this.newRecord.userId);
            if (userId === NaN || userId <= 0) {
                this.$bvToast.toast("user id error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let expireTime = this.newRecord.expireTime;
            if (expireTime === null || expireTime === "") {
                this.$bvToast.toast("expire time error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }
            let eTime = moment(expireTime).utc().endOf("day").unix();

            let countryCode=this.newRecord.countryCode
            if(countryCode===""||countryCode===this.ALL_Country){
                this.$bvToast.toast("country code error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let stor_total_GB = parseInt(this.newRecord.storageTotalGB);
            if (stor_total_GB === NaN || stor_total_GB < 20) {
                this.$bvToast.toast("storage error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let bandwidth_Mbps_min = parseInt(this.newRecord.bandwidthMbpsMin);
            if (bandwidth_Mbps_min === NaN || bandwidth_Mbps_min <= 0) {
                this.$bvToast.toast("bandwidth_Mbps_min error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let bandwidth_Mbps_max = parseInt(this.newRecord.bandwidthMbpsMax);
            if (bandwidth_Mbps_max === NaN || bandwidth_Mbps_max <= 0) {
                this.$bvToast.toast("bandwidth_Mbps_max error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (bandwidth_Mbps_max<bandwidth_Mbps_min){
                this.$bvToast.toast("bandwidth error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let count = parseInt(this.newRecord.count);
            if (count === NaN || count <= 0) {
                this.$bvToast.toast("count error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let cpu=this.newRecord.cpu
            let op_sys=this.newRecord.op_sys

            this.add_price_show_overlay = true;
            //addAgentNode(userId,countryCode,bandwidth_Mbps_min,bandwidth_Mbps_max,expireTime,stor_total_GB,cpu, op_sys,count,token)
            let resp = await this.$api.agentNode.addAgentNode(
                userId,
                countryCode,
                bandwidth_Mbps_min,
                bandwidth_Mbps_max,
                eTime,
                stor_total_GB,
                cpu,
                op_sys,
                count,
                this.$store.state.auth.my_web_token
            );
            this.add_price_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("price record added", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });

            this.newRecord = {
                userId: 0,
                countryCode: "",
                bandwidthMbpsMin: 700,
                bandwidthMbpsMax: 800,
                storageTotalGB: 80,
                cpu: "",
                op_sys: "",
                expireDate: "",
                count: 0,
            };
            this.refreshTable();
        },
    },

    mounted() {
        this.refreshTable();
    },
    data() {
        return {
            //for table
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Ip",
                    field: "ip",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "User ID",
                    field: "user_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Location",
                    field: "country_code",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Bandwidth_Mbps_min",
                    field: "bandwidth_Mbps_min",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Bandwidth_Mbps_max",
                    field: "bandwidth_Mbps_max",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "stor_GB",
                    field: "stor_total_GB",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Status",
                    field: "status",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Expire Date",
                    field: "expire_unixtime",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {},

            table_show_overlay: true,
            modal_row_view_overlay: false,
            add_price_show_overlay: false,

            queryCondition: {
                id: null,
                userId: null,
                ip: null,
                expireTime: null,
                country_code: null,
                continent_code: null,
                status: null,
            },

            newRecord: {
                userId: 0,
                countryCode: "",
                bandwidthMbpsMin: 700,
                bandwidthMbpsMax: 800,
                storageTotalGB: 80,
                cpu: "",
                op_sys: "",
                expireDate: "",
                count: 0,
            },

            nodeStatusOptions: [
                { text: "running", value: "running" },
                { text: "stopped", value: "stopped" },
            ],

            ALL_Continent: ALL_Continent,
            ALL_Country: ALL_Country,
            CountryMap: CountryMap,
            ContinentMap: ContinentMap,
            ContinentOption: ContinentOption,
            CountryOption: CountryOption,
        };
    },
};
</script>
